export class UtilRuta {
  static irRutaPrincipal(rol: number): string[] {
    const RUTA = {
      1: () => ['usuario/lista-usuarios'],
      2: () => ['aprende-reciclar'],
      5: () => ['planta-reciclaje'],
    };
    const ruta = RUTA[rol as keyof typeof RUTA];
    return ruta ? ruta() : ['inicio'];
  }

  static invertirCoordenadas(coordenadas: number[][]): number[][] {
    return coordenadas.map((coordenada) => (coordenada.reverse()))
  }
}

